@font-face {
  font-family: 'FBFONT';
  src:
    /* Safari, Android, iOS */ url('./assets/fonts/UniversalKnowledge.woff') format('woff'),
    /* Modern Browsers */ url('./assets/fonts/UniversalKnowledge.woff2') format('woff2'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
  text-transform: uppercase;
}

body {
}

// Body border

.badge-boerse {
  width: 200px;
  position: absolute;
  top: 80px;
  right: 40px;
  @include media-breakpoint-down(md) {
    width: 130px;
  }
}
.badge-faq {
  width: 200px;
  position: absolute;
  top: 260px;
  right: 40px;
  @include media-breakpoint-down(md) {
    width: 130px;
    top: 75vh;
    left: 40px;
    right: auto;
  }
}

body:before,
body:after {
  content: '';
  position: fixed;
  z-index: 10000 !important;
  background: $gray-500;
  left: 0;
  right: 0;
  height: 1rem;
}
body:before {
  top: 0;
}
body:after {
  bottom: 0;
}
body {
  border-left: 1rem solid $gray-500;
  border-right: 1rem solid $gray-500;
}

#root {
  background: black;
  background-image: linear-gradient(rgba($color-dark, 0.8), rgba($color-dark, 0), rgba($color-dark, 0.7)),
    url('./assets/images/fbbs.jpg');
  background-repeat: repeat;
  background-position: center center;
  background-size: contain;
}

.product-content {
  background: transparent;
}

.btn {
  text-transform: uppercase;
  font-family: 'FBFONT', $font-family-sans-serif;
}

/* @media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
  .product-detail .container {
    max-width: 80%;
  }
} */

.w-40 {
  width: 80%;
  @include media-breakpoint-up(md) {
    width: 33.3333333333%;
  }
}

.product-content h1 {
  @include media-breakpoint-up(md) {
    //@extend .text-truncate;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
legend {
  text-transform: uppercase;
  font-weight: 500 !important;
}

.validation-alert--message {
  color: $red;
}

#root {
  padding-top: $calculated-navbar-height + 3rem;
  @include media-breakpoint-down(sm) {
    padding-top: $calculated-navbar-height-sm + 1rem;
  }
}

.card.bg-light {
  background-color: $gray-800 !important;
  color: $white;
}

.checkout .when-valid {
  background-color: #000;
}

.navbar-light .offcanvas-collapse,
.navbar-dark .offcanvas-collapse {
  background: rgba($color: $color-dark, $alpha: 0.7);
  top: 0;
  padding-top: 2rem;
  @include media-breakpoint-down(sm) {
    top: 0;
  }
  &.offcanvas-collapse-right {
    background: rgba($color: $color-dark, $alpha: 0.7);
    padding-top: 2rem !important;
    .table-dark {
      background-color: rgba($color-dark, 0.5);
    }
    h5 {
      color: $white;
    }
  }
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
}

// NAV

.navbar-dark {
  background-color: transparent;
  color: $white;
  transition: background-color 0.2s;
  body[data-scrolled] & {
    background-color: rgba(#512c19, 0.75) !important;
  }
}

.navbar-dark .offcanvas-collapse {
  .navbar-nav .nav-item .nav-link {
    //font-family: 'Visual-Regular', $font-family-sans-serif;
    //text-transform: uppercase;
    font-size: 1.2rem;
    background: transparent;
    color: $white;
    &.nav-link-small {
      font-size: 80%;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
    }
    &.nav-link-cat {
      font-size: 110%;
    }
  }
}

#header > .navbar {
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 5rem;
  @include media-breakpoint-down(sm) {
    padding-top: 0.35rem;
  }
  .navbar-brand {
    img {
      max-height: 2.5rem;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
    @include media-breakpoint-down(xs) {
      img {
        //width: 112px;
      }
    }
  }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    height: 1px;
  }
}

// JUMBOTRON
.header-jumbotron {
  background-color: $dark;
  color: $white;
  background-image: url('./assets/images/header.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 75vh;
  margin-top: $calculated-navbar-height;
  //transform: translateY(-$calculated-navbar-height);
  @include media-breakpoint-down(sm) {
    //transform: translateY(-$calculated-navbar-height-sm);
    height: 50vh;
  }
}

// MAKI

.maki.card {
  background-color: white;
  margin-bottom: $grid-gutter-width;
  .card-body {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: $card-spacer-x;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s linear;
    display: flex;
    .card-title {
      color: $black;
      justify-content: center;
      visibility: hidden;

      align-items: center;
      overflow: hidden;
      span.title {
        font-weight: 500;
        display: block;
        font-size: 1.5rem;
        max-width: 100%;
        font-family: $headings-font-family;
        text-transform: uppercase;
        @extend .text-truncate;
      }
      span.sub-title {
        font-size: smaller;
        margin-bottom: 5px;
      }
      span.price {
        font-size: 1rem;
        font-weight: bold;
      }
    }
    .badge {
      z-index: 2;
      transform: rotate(2.5deg);
      font-size: 1em;
      font-weight: bold;
      position: absolute;
      top: -5px;
      right: -5px;
    }
  }
  &:hover {
    img {
      background-color: $white;
    }
    .card-body {
      background: rgba($white, 0.8);
    }
    .card-title {
      visibility: visible;
      opacity: 1;
    }
  }
}

// NIGIRI

.nigiri.card {
  background-color: white;
  border: 0;
  //border-bottom: 1px solid rgba($white, 0.25);
  .nigiri-content {
    padding: 1.25rem;
    margin-top: 0.2rem;
    color: $black;
    @include media-breakpoint-up(md) {
      align-items: center;
    }
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h3-font-size;
    color: $gray-600;
    font-family: 'FBFONT';
    align-items: center !important;
  }
  .nigiri-body {
    font-size: $h2-font-size;
    border-left: 0;
    text-transform: uppercase;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 1em;
  }
  .title {
    font-size: $font-size-base * 1.2;
    margin-bottom: 0;
    color: $black;
    font-family: $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: $font-size-base;
    margin-bottom: 0;
    color: $gray-500;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-family: 'FBFONT', $font-family-sans-serif !important;
    font-weight: 400 !important;
    font-size: $h3-font-size !important;
    color: $black;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date span {
    margin-top: -1.5rem;
    font-weight: bold;
  }

  .nigiri-date * + * {
    margin-top: 1rem;
  }
}

.table {
  //@extend .table-dark;
}

.card,
.navbar-light .offcanvas-collapse {
  color: $black;
}

// Products

// body.product-listing-page {
//   #root {
//     @include media-breakpoint-down(sm) {
//       padding-top: $calculated-navbar-height-sm;
//     }
//   }
// }

body.product-detail-page {
  .carousel-control-prev,
  .carousel-control-next {
    color: $color-accent;
  }
  .carousel-indicators {
    position: relative;
  }
}

.product-detail-right {
  // display: flex;
  // flex-direction: column;
  // height: 100%;
  // justify-content: center;

  .product-supertitle {
    display: none;
  }
  .product-title {
    //text-transform: uppercase;
    font-weight: normal;
  }
  .product-subtitle {
    font-size: $h3-font-size;
    font-weight: 500;
  }
  .price {
    font-weight: normal;
  }
}

// Footer

.prefooter-wrapper {
  color: $black;
  background-color: $white;

  .prefooter-text {
    align-self: center;
    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
    }
  }

  .prefooter-icon-list {
    align-items: center;
    flex-wrap: wrap;

    .row {
      justify-content: space-around;

      @include media-breakpoint-down(xs) {
        row-gap: 20px;
      }

      div {
        text-align: center;
        @include media-breakpoint-up(xxl) {
          flex: 0 0 16.6666666667% !important;
          max-width: 16.6666666667% !important;
        }
      }
    }

    img {
      max-width: 100px !important;
      max-height: 100px;
      aspect-ratio: 1;
    }
  }
}

#footer {
  //box-shadow: $box-shadow-inverse-xl;
  font-size: 90%;
  min-height: 1vh;
  color: $body-color;
  line-height: 1.75rem;
  background: linear-gradient(rgba($color-dark, 0), rgba($color-dark, 0.9));

  a {
    color: $body-color;
    text-decoration: none;
    &:hover {
      background-color: transparent;
    }
  }
  .copyright {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .copyright-logos {
    img {
      max-height: 2rem;
    }
    margin-top: 25px;
  }
}

.offcanvas-collapse {
  @include media-breakpoint-up(sm) {
    top: $calculated-navbar-height;
  }
  box-shadow: 15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  box-shadow: none;
  background-color: transparent;
}

.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
  height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;
}

.when-valid {
  //background: rgba($darkgray, 0.9);
  transition: all 0.3s;
  .modal & {
    background-color: inherit;
  }
}

.sticky-bottom.when-valid {
  &:not(.is-valid) {
    background-color: $gray-100;
    .btn-link {
      color: $black;
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// Loading Spinner
.loading-spinner-content {
  background: transparent !important;
}

#initial-spinner.spinner-boarder.text-dark {
  color: $color-accent !important;
}

// List Step Fix

ol.list-steps {
  $border-width: 0.5rem;
  li {
    &.activatable::before {
      border: $border-width solid $gray-100;
    }

    &.active::before {
      border: $border-width solid lighten($color-accent, 10%);
    }

    &.activatable::after {
      //background: lighten($activatable, $border-lighten);
      background: $gray-300;
    }

    &.active.activatable::after {
      //background: lighten($activatable, $border-lighten);
      background: lighten($color-accent, 15%);
    }
  }
}

// Home

body.g8-page {
  .g8-page-content {
    padding: 0;
  }
  .hero {
    height: 100vh;
  }
  .header-image {
    max-height: 30vh;
    width: auto;
    // width: 80%;
    // @include media-breakpoint-down(sm) {
    //   width: 100%;
    // }
  }
}

body.landing-page,
body.ticket-listing-page {
  overflow-x: hidden;

  h1 {
    @include font-size(4rem);
    text-align: center;
    @include media-breakpoint-down(sm) {
      @include font-size(6rem);
      margin-bottom: 0;
    }
  }
  #main {
    min-height: 100vh;
  }
  .navbar-brand {
    opacity: 0;
  }
  .hero {
    height: 100vh;
    margin-top: -($calculated-navbar-height + 3rem);
    @include media-breakpoint-down(sm) {
      margin-top: -$calculated-navbar-height-sm + 1rem;
    }
    h1 {
      @include media-breakpoint-down(sm) {
        @include font-size(2rem);
        margin-bottom: 0;
      }
    }
    .arrow {
      @include font-size(3rem);
      @include media-breakpoint-down(sm) {
        @include font-size(2rem);
      }
      color: rgba($white, 1);
      animation-name: color;
      animation-duration: 2s;
      animation-timing-function: ease-out;
      animation-direction: alternate;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
    @keyframes color {
      0% {
        color: rgba($white, 0.5);
      }
      25% {
        color: rgba($white, 1);
      }
      50% {
        color: rgba($white, 0.5);
      }
      100% {
        color: rgba($white, 1);
      }
    }
  }

  .header-image {
    max-height: 50vh;
    width: auto;
    // width: 80%;
    // @include media-breakpoint-down(sm) {
    //   width: 100%;
    // }
  }

  .list-page-highlights-wrapper {
    overflow-x: hidden;
  }

  .list-page-highlights {
    background-color: transparent;
    position: relative;
    z-index: 1;
    padding-top: 3rem;
    padding-bottom: 1rem;
    h1 {
      transform: rotate(-1.5deg);
    }
    .maki.card {
      box-shadow: $box-shadow-xl;
    }
    #product-listing {
      @include media-breakpoint-up(md) {
        transform: rotate(-1.5deg);
      }
    }
    .container-fluid {
      @include media-breakpoint-up(md) {
        max-width: 90%;
      }
    }
  }

  .list-page-highlights::before {
    background-color: rgba($color-accent, 0.8);
    position: absolute;
    content: '';
    display: block;
    top: 0;
    bottom: 0;
    width: 200%;
    left: -50%;
    z-index: -1;
    transform: rotate(-1.5deg);
    box-shadow: $box-shadow-xl;
  }
  .list-page-tickets {
    margin-top: 6rem;
  }
}

body.ticket-listing-page[data-scrolled],
body.landing-page[data-scrolled] {
  .navbar-brand {
    opacity: 1;
    transition: all 0.2s ease;
    //transform: translateY(0);
  }
}

// Ticket Detail

body.ticket-detail-page {
  #root {
    padding-top: $calculated-navbar-height + 1rem;
    @include media-breakpoint-down(sm) {
      padding-top: $calculated-navbar-height-sm + 1rem;
    }
  }
  .card.article-title {
    background-color: $white;
    border: 0;
    .super-title {
      color: $gray-700;
    }
    h1 {
      color: $black;
      font-size: $h1-font-size;
      small {
        color: $black;
      }
    }
  }
  .product-info-bar {
    background-color: $gray-900;
    font-family: 'FBFONT', $font-family-sans-serif;
    text-transform: uppercase;
    box-shadow: $box-shadow-xl;

    i {
      color: $gray-100 !important;
    }
    //box-shadow: none;
  }

  table.variant-listing {
    overflow: hidden;

    @extend .bg-black;
    thead {
      display: none;
    }
    th {
      //padding-left: 0;
    }
    tr > td {
      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  .seatingplan-container {
    color: $black !important;
    .table,
    table {
      color: $black !important;
    }
  }

  .seatingplan-tooltip table {
    color: $black !important;
  }

  .popover,
  .popover-body,
  .popover-header {
    color: $black !important;
  }

  // TABS TICKET DETAIL
  article.ticket-detail {
    .nav-tabs {
      border-bottom: 1px solid $gray-700;
    }
    .nav-tabs .nav-link {
      color: $gray-500;
      border: 0;
      border-bottom: 1px solid $gray-700;
    }
    .nav-tabs .nav-link.active {
      //background: linear-gradient(rgba($black, 0.0), $black);
      background: transparent;
      color: $gray-100;
      border-bottom: 1px solid $gray-100;
      &:hover {
        color: lighten($gray-100, 10%);
        background: transparent;
      }
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border-bottom: 1px solid $gray-100;
      background: transparent;
      color: $gray-300;
    }
    section.detail-page {
      a {
        color: $white;
      }
    }
  }
  .blurry-background-image {
    background-color: transparent;
  }
  .blurry-background-image:before {
    background: transparent;
  }
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
      padding-left: 0;
      padding-right: 0;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    tbody {
      background-color: $black !important;
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    .add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// STYLING PRODUCT-CONFIG

body.product-detail-page {
  .modal-body .text-muted {
    color: $white;
    opacity: 1 !important;
  }
  .kachel-header.active {
    background-color: lighten($color-accent, 10%);
    border-color: lighten($color-accent, 10%);
  }
}

// body.product-detail-page {
//   .modal-dialog {
//     @include media-breakpoint-up(sm) {
//       max-width: 600px;
//     }
//   }
//   .radio-list-group {
//     box-shadow: none !important;
//   }
//   .modal-body .text-muted {
//     color: $white;
//     opacity: 1 !important;
//   }
//   .kachel-header {
//     background-color: $white !important;
//     border-color: $white;
//     &.active {
//       border-color: $white;
//       background-color: $white !important;
//     }
//     .media {
//       justify-content: center !important;
//       flex-direction: column;
//       .product-thumb {
//         margin-right: 0 !important;
//         @extend .mb-2;
//         width: 50%;
//         height: auto;
//         border-radius: 0;
//       }
//     }
//     .custom-radio {
//       display: none;
//     }
//   }
//   .kachel-container {
//     display: flex;
//     padding-bottom: 1rem;
//     flex-direction: row;
//     flex-wrap: wrap;
//     justify-content: center;
//     .custom-radio {
//       display: none;
//     }
//     .kachel {
//       img {
//         display: none;
//       }
//       background-color: rgba(white, 0.2);
//       display: flex;
//       h5 {
//         font-size: 1.2rem !important;
//       }
//       justify-content: center;
//       height: 4rem;
//       flex: 1 1 20%;
//       color: $white;
//       //margin: 0.25rem 0;
//       &:hover {
//         background-color: rgba($gray-200, 0.5);
//       }
//       &.active {
//         background-color: $color-accent;
//         border-color: $color-accent;
//         color: $white;
//         font-weight: 700;
//         margin-top: 0;
//       }
//     }
//     .list-group-item {
//       color: $white;
//     }
//   }
// }

.custom-select {
  font-family: $font-family-base !important;
}

// BROTSTOCK

body.ticket-detail-page {
  #root {
  }

  .card.article-title {
    background-color: #e3dccd;
    .super-title {
      color: #e3dccd;
    }
    h1 {
      color: #512c19;
      small {
        color: #512c19;
      }
    }
  }
  .product-info-bar {
    background-color: #512c19;
    i {
      color: #e3dccd !important;
    }
    //box-shadow: none;
  }

  table.variant-listing {
    background-color: #512c19 !important;
  }
  @include media-breakpoint-only(xs) {
    table.variant-listing {
      background-color: transparent !important;
    }

    table.variant-listing tr {
      background-color: #512c19 !important;
      padding: 0.75rem;
    }

    table.variant-listing tbody {
      background-color: transparent !important;
    }

    table.variant-listing tr > td:last-of-type {
      padding-bottom: 0;
    }
  }
}

.nigiri-container#article-51359 {
  overflow: visible;
  .nigiri {
    box-shadow: $box-shadow-xl;
  }
  .nigiri-content,
  .card {
    justify-content: center;
    //background: #512c19;
    //background-image: url('./assets/images/fbbs.jpg');
    //color: #e3dccd !important;
  }
  .location:before {
    content: 'BROTSTOCK';
    @include media-breakpoint-up(md) {
      content: 'FETTES BROT … BROTSTOCK';
    }
    line-height: 2.75rem;
    display: block;
    font-size: $h1-font-size;
    color: $black !important;
  }
  .location,
  .nigiri-date {
    color: #957869 !important;
  }
}
